<template>
  <UiEmptyState
    :image-src="require('@/assets/img/something_went_wrong_error.svg')"
    :title-text="$t('This order can’t be returned right now')"
  >
    <template #description>
      <p>
        {{
          $t(
            'This order contains items that are too heavy or too large to be returned through the return portal. Contact us for support.'
          )
        }}
      </p>
    </template>
    <template
      v-if="supportUrl"
      #footer
    >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :href="supportUrl"
          :style="brandStyle"
        >{{ $t('Support') }}</a>
      </UiButton>
    </template>
    <template
      v-else-if="brandWebsiteUrl"
      #footer
    >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :href="brandWebsiteUrl"
          :style="brandStyle"
        >{{ $t('Support') }}</a>
      </UiButton>
    </template>
  </UiEmptyState>
</template>
<script>
import { brandColourMixin, allUrls } from '@/components/mixins'
import { UiButton, UiEmptyState } from '@sendcloud/ui-library'

export default {
  name: 'WeightError',
  components: {
    UiEmptyState,
    UiButton,
  },
  mixins: [brandColourMixin, allUrls],
}
</script>
